// @ts-nocheck
import { types } from "./types";
class objects {
    static itIs(obj) { return typeof obj === "object" && obj !== null && typeof obj !== "undefined"; }
    static filter(target, predicate) { const obj = {}; objects.each(target).forEach((v, i, a) => { if (predicate(v[0], v[1], target)) {
        objects.set(obj, v[0], v[1]);
    } }); return obj; }
    static update(target, obj) { if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); if (!objects.itIs(obj))
        throw new TypeError("'obj' must be an object!"); Object.keys(obj).forEach((key, index, arr) => { try {
        if (typeof target[key] === "object" && typeof obj[key] === "object") {
            objects.update(target[key], obj[key]);
            return;
        }
    }
    catch (e) {
        if (key in target) {
            target[key] = obj[key];
        }
    } }); return target; } /** Compare first object with the second and return true if the objects are the same */
    static compare(o1, o2) { return !objects.each(Object.keys(o1).length > Object.keys(o2).length ? o1 : o2).find(_ref => { let [path, value] = _ref; if (typeof value !== "object") {
        return objects.get(Object.keys(o1).length > Object.keys(o2).length ? o2 : o1, path) !== value;
    } }); }
    static includes(o1, o2) { const obj = Object.keys(o1).length > Object.keys(o2).length ? o1 : o2; const aobj = Object.keys(o1).length > Object.keys(o2).length ? o2 : o1; return Array.isArray(obj) ? !!Object.values(obj).find(value => { if (typeof value !== "object") {
        return Object.values(aobj).find(xvalue => xvalue === value);
    } }) : !!Object.keys(obj).find(key => { if (typeof obj[key] !== "object") {
        return Object.keys(aobj).find(xkey => xkey === key && aobj[xkey] === obj[key]);
    } }); }
    static keys(target) { let exclude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : []; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); const array = Array.isArray(this === null || this === void 0 ? void 0 : this.array) ? this === null || this === void 0 ? void 0 : this.array : []; Object.keys(target).forEach(v => { if (exclude.includes((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse().join(".")))
        return; if (objects.itIs(target[v]))
        objects.keys.call({ array, parent: this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v] }, target[v]); if (!!array) {
        if (!array.includes((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse())) {
            array.push((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse());
        }
    } }); return array.reverse(); }
    static values(target) { let exclude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : []; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); const array = Array.isArray(this === null || this === void 0 ? void 0 : this.array) ? this === null || this === void 0 ? void 0 : this.array : []; Object.keys(target).forEach(v => { if (exclude.includes((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse().join(".")))
        return; if (objects.itIs(target[v]))
        objects.values.call({ array, parent: this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v] }, target[v]); if (!!array) {
        if (!array.includes((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse())) {
            array.push((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse());
        }
    } }); return array.map(v => { return objects.get(target, v); }); }
    static each(target) { let exclude = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : []; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); const array = Array.isArray(this === null || this === void 0 ? void 0 : this.array) ? this === null || this === void 0 ? void 0 : this.array : []; Object.keys(target).forEach(v => { if (exclude.includes((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse().join(".")))
        return; if (objects.itIs(target[v]))
        objects.keys.call({ array, parent: this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v] }, target[v]); if (!!array) {
        if (!array.includes((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse())) {
            array.push((this !== null && this !== void 0 && this.parent ? [v, ...(this === null || this === void 0 ? void 0 : this.parent)] : [v]).reverse());
        }
    } }); return array.map(v => { return [v, objects.get(target, v)]; }); }
    static get(target, accessor) { accessor = Array.isArray(accessor) ? accessor : [accessor]; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); if (!Array.isArray(accessor))
        throw new TypeError("'accessor' must be an array!"); var pass = (this === null || this === void 0 ? void 0 : this.pass) || { target }; accessor.forEach((key, index, arr) => { const curIndex = index + 1; if (curIndex !== arr.length && typeof target[key] === "object") {
        objects.get.call({ pass }, target[key], [...accessor].splice(curIndex));
        return;
    } if (curIndex === arr.length && arr.length === 1) {
        pass.target = target[key];
    } }); if (!(this !== null && this !== void 0 && this.pass)) {
        return pass.target;
    } }
    static simpleFilterKeys(target, keys) { var obj = { ...target }; keys.forEach(v => { if (Array.isArray(v))
        return; delete obj[v]; }); return obj; }
    static recompose(target, getValue) { const obj = {}; objects.each(target).reverse().forEach(_ref2 => { let [path, value] = _ref2; const v = getValue ? getValue(obj, path, value) : value; if (v) {
        objects.defineProperty(obj, path, { value: v });
    } }); return obj; }
    static complexDefine(target, accessor) { let descriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {}; let getValue = arguments.length > 3 ? arguments[3] : undefined; accessor = Array.isArray(accessor) ? accessor : [accessor]; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); if (!Array.isArray(accessor))
        throw new TypeError("'accessor' must be an array!"); var pass = (this === null || this === void 0 ? void 0 : this.pass) || { target }; accessor.forEach((key, index, arr) => { const curIndex = index + 1; if (curIndex === 1 && arr.length > 1 && typeof (getValue ? getValue(target, key) : target[key]) !== "object") {
        target[key] = {};
        objects.complexDefine.call({ pass }, getValue ? getValue(target, key) : target[key], [...accessor].splice(curIndex), descriptor, getValue ? getValue : () => { });
        return;
    } if (curIndex !== arr.length && typeof (getValue ? getValue(target, key) : target[key]) === "object") {
        objects.complexDefine.call({ pass }, getValue ? getValue(target, key) : target[key], [...accessor].splice(curIndex), descriptor, getValue ? getValue : () => { });
        return;
    } if (curIndex === arr.length && arr.length === 1) {
        var _descriptor;
        if (typeof descriptor !== "object")
            descriptor = {};
        if (((_descriptor = descriptor) === null || _descriptor === void 0 ? void 0 : _descriptor.value) === undefined)
            descriptor.value = {};
        if (typeof descriptor === "object")
            descriptor = { writable: true, enumerable: true, configurable: true, ...descriptor };
        try {
            Reflect.defineProperty(target, key, descriptor);
        }
        catch (e) {
            console.error(e);
        }
        pass.target = getValue ? getValue(target, key) : target[key];
    } }); if (!(this !== null && this !== void 0 && this.pass)) {
        return pass.target;
    } }
    static defineProperty(target, accessor) { let descriptor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {}; accessor = Array.isArray(accessor) ? accessor : [accessor]; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); if (!Array.isArray(accessor))
        throw new TypeError("'accessor' must be an array!"); var pass = (this === null || this === void 0 ? void 0 : this.pass) || { target }; accessor.forEach((key, index, arr) => { const curIndex = index + 1; if (curIndex === 1 && arr.length > 1 && typeof target[key] !== "object") {
        target[key] = {};
        objects.defineProperty.call({ pass }, target[key], [...accessor].splice(curIndex), descriptor);
        return;
    } if (curIndex !== arr.length && typeof target[key] === "object") {
        objects.defineProperty.call({ pass }, target[key], [...accessor].splice(curIndex), descriptor);
        return;
    } if (curIndex === arr.length && arr.length === 1) {
        var _descriptor2;
        if (typeof descriptor !== "object")
            descriptor = {};
        if (((_descriptor2 = descriptor) === null || _descriptor2 === void 0 ? void 0 : _descriptor2.value) === undefined)
            descriptor.value = {};
        if (typeof descriptor === "object")
            descriptor = { writable: true, enumerable: true, configurable: true, ...descriptor };
        try {
            Reflect.defineProperty(target, key, descriptor);
        }
        catch (e) {
            console.error(e);
        }
        pass.target = target[key];
    } }); if (!(this !== null && this !== void 0 && this.pass)) {
        return pass.target;
    } }
    static set(target, accessor, value) { let descriptor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {}; accessor = Array.isArray(accessor) ? accessor : [accessor]; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); if (!Array.isArray(accessor))
        throw new TypeError("'accessor' must be an array!"); var pass = (this === null || this === void 0 ? void 0 : this.pass) || { target }; accessor.forEach((key, index, arr) => { const curIndex = index + 1; if (curIndex === 1 && arr.length > 1 && typeof target[key] !== "object") {
        target[key] = {};
        objects.set.call({ pass }, target[key], [...accessor].splice(curIndex), value, descriptor);
        return;
    } if (curIndex !== arr.length && typeof target[key] === "object") {
        objects.set.call({ pass }, target[key], [...accessor].splice(curIndex), value, descriptor);
        return;
    } if (curIndex === arr.length && arr.length === 1) {
        if (value === undefined)
            value = {};
        if (typeof descriptor !== "object")
            descriptor = {};
        if (typeof descriptor === "object")
            descriptor = { writable: true, enumerable: true, configurable: true, ...descriptor };
        try {
            Reflect.defineProperty(target, key, { value: value, ...descriptor });
        }
        catch (e) {
            target[key] = value;
        }
        pass.target = target[key];
    } }); if (!(this !== null && this !== void 0 && this.pass)) {
        return pass.target;
    } }
    static delete(target, accessor) { accessor = Array.isArray(accessor) ? accessor : [accessor]; if (!objects.itIs(target))
        throw new TypeError("'target' must be an object!"); if (!Array.isArray(accessor))
        throw new TypeError("'accessor' must be an array!"); var pass = (this === null || this === void 0 ? void 0 : this.pass) || { target }; accessor.forEach((key, index, arr) => { const curIndex = index + 1; if (curIndex !== arr.length && typeof target[key] === "object") {
        objects.delete.call({ pass }, target[key], [...accessor].splice(curIndex));
        return;
    } if (curIndex === arr.length && arr.length === 1) {
        delete target[key];
        pass.target = target[key];
    } }); if (!(this !== null && this !== void 0 && this.pass)) {
        return pass.target;
    } }
    static simpleDelete(target, path) { const keys = typeof path === "string" ? path.split(".").filter(value => value) : path; return objects.delete(target, keys); }
    static simpleSet(target, path, value) { let descriptor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {}; const keys = typeof path === "string" ? path.split(".").filter(value => value) : path; return objects.set(target, keys, value, descriptor); }
    static simpleGet(target, path) { const keys = typeof path === "string" ? path.split(".").filter(value => value) : path; return objects.get(target, keys); }
    static check(obj, condition, execute) { Object.keys(obj).forEach(x => { if (condition(x, obj[x])) {
        execute(obj, x, obj[x]);
    } if (typeof obj[x] === "object") {
        objects.check(obj[x], condition, execute);
    } }); }
    static replace(obj, from, to) { let opts = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : { what: "keys", arraysOnSameValues: true, deepReplace: true }; from = Array.isArray(from) ? from : [from]; to = Array.isArray(to) ? to : [to]; opts.deepReplace = types.isDefined(opts === null || opts === void 0 ? void 0 : opts.deepReplace) ? opts === null || opts === void 0 ? void 0 : opts.deepReplace : true; opts.what = types.isDefined(opts === null || opts === void 0 ? void 0 : opts.what) ? opts === null || opts === void 0 ? void 0 : opts.what : "keys"; opts.arraysOnSameValues = types.isDefined(opts === null || opts === void 0 ? void 0 : opts.arraysOnSameValues) ? opts === null || opts === void 0 ? void 0 : opts.arraysOnSameValues : true; var { what, arraysOnSameValues, deepReplace } = opts; if (typeof obj !== "object")
        throw new TypeError("'obj' argument must be an object!"); if (what !== "keys")
        throw new TypeError("'what' argument must be 'keys'!"); if (!Array.isArray(from))
        throw new TypeError("'from' argument must be an array!"); if (!Array.isArray(to))
        throw new TypeError("'to' argument must be an array!"); var properties = Object.keys(obj).map((v, i, a) => { return { index: i, value: obj[v], key: v, type: typeof obj[v] }; }); var fromProperties = properties.filter(prop => from.includes(what === "keys" ? prop.key : prop.value)); var rawFilteredFromKeys = fromProperties.map(prop => prop.key); var values = properties.map(prop => prop.value); var rawFilteredValues = values.filter((value, index, arr) => index === arr.findIndex(t => t === value)); if (deepReplace === true) {
        properties.forEach(prop => { if (prop.type === "object")
            objects.replace(prop.value, from, to, opts); });
    } to.forEach((toK, i, a) => { function finish() { if (value !== undefined) {
        if (what === "keys") {
            obj[toK] = value;
        }
    } } var value; const index = i + 1; if (what === "keys") {
        if (index > fromProperties.length) {
            value = obj[rawFilteredFromKeys[rawFilteredFromKeys.length - 1]];
            finish();
            return;
        }
        if (index < fromProperties.length && index === to.length && arraysOnSameValues === true) {
            value = [...rawFilteredFromKeys].splice(index - 1).map(key => obj[key]);
            finish();
            return;
        }
        if (fromProperties[i]) {
            value = obj[rawFilteredFromKeys[i]];
            finish();
            return;
        }
    } }); rawFilteredFromKeys.forEach((key, i) => { if (what === "keys") {
        delete obj[key];
    } }); return obj; }
}
export { objects as default, objects };
